import React, {useContext, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import data from '../data';
import {UserContext, AuthUserContext} from '../userContext';
import {useFirebase} from '../components/firebase';
import TestNav from "../components/testNav";

function TestGroup() {
  return (
    <UserContext.Consumer>
      {user => (
        <Content user={user} />
      )}
    </UserContext.Consumer>
  );
}

function Content({user}) {
  const firebase = useFirebase();
  const authContext = useContext(AuthUserContext);
  const authUser = authContext.authUser;
  const history = useHistory();
  const params = useParams();
  const groupKey = params.grp || 'min';
  const group = data[groupKey];

  let [selected, setSelected] = useState(user.getSelectedAnswers(groupKey));

  function toggleSelection(key, evt) {
    setSelected((prev) => {
      if (evt.target.checked) {
        return [
          ...prev,
          key
        ];
      } else {
        return prev.filter((k) => k !== key);
      }
    });
  }

  function goNext() {
    user.saveGroup(groupKey, selected);

    const results = user.currentResults;
    const nextUrl = user.getNextUrl(groupKey);

    // If going to results page, save them since test is complete
    if (nextUrl.indexOf('/result') === 0 && authContext.isLoggedIn) {
      firebase.saveUserResult(authUser.uid, results);
    }

    if (nextUrl) {
      history.push(nextUrl);
    }
  }
  return (
    <div className="page page-test page-test-group">
      <TestNav groupKey={groupKey} />

      <div className="test-container">
        <div className="left">

          <div className="group-instructions">
            <p>Think over the past season.</p>
            <p className="text-bold">Where have you felt stressed?</p>
            <p>It might have just been once.</p>
          </div>

          <div className="option-list">

            {Object.keys(group.options).map((key) => (

              <label className="option-item" key={`${groupKey}.${key}`}>
                <input type="checkbox"
                       value="1"
                       checked={selected.includes(key)}
                       onChange={(evt) => toggleSelection(key, evt)}/>
                <CheckIcon/>
                <span className="option-name">
                      {group.options[key]}
                    </span>
              </label>
            ))}
          </div>

          <div className="text-center">
            <button type="button" className="btn" onClick={() => goNext()}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestGroup;