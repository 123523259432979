import React, {useContext, useEffect, useState} from 'react';
import InfoIcon from '@material-ui/icons/InfoOutlined'
import HistoryIcon from '@material-ui/icons/HistoryOutlined'
import {Link} from "react-router-dom";
import {AuthUserContext} from "../userContext";
import {useFirebase} from "../components/firebase";

function Home() {
  const authContext = useContext(AuthUserContext);
  const firebase = useFirebase();
  const [results, setResults] = useState();

  useEffect(() => {
    const getResults = async (uid) => {
      setResults(await firebase.getUserResults(uid));
    }
    if (authContext.isLoggedIn && authContext.authUser) {
      getResults(authContext.authUser.uid)
    } else {
      setResults(null);
    }
  }, [authContext.isLoggedIn, authContext.authUser]);

  if (authContext.isLoggedIn) {

  }
  return (<div className="page page-home">
    {results
      ? <div className="history-link">
        <Link to="/history" className="icon-link btn btn-outline btn-accent">
          <HistoryIcon />
          View Past Results
        </Link>
      </div>
      :null
    }

    <footer>
      <p className="mmct-ref">
        A cross-cultural worker stress inventory based
        on <a href="https://www.mmct.org/stress-and-resilience" target="_blank" rel="noreferrer">mmct.org/stress-and-resilience</a>
      </p>

      <div className="about-link">
        <Link to="/about" className="icon-link">
          <InfoIcon />
          Learn More
        </Link>
      </div>
    </footer>
  </div>);
}

export default Home;