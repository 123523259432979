import React, {useContext} from 'react';
import data from "./data";

export const AuthUserContext = React.createContext({isLoggedIn: false, authUser: null});
export const UserContext = React.createContext(null);

export const useUser = () => {
  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new Error(
      "useUser must be used within a UserContext.Provider"
    );
  }
  return userContext;
};

class User {
  constructor() {
    // Use a singleton so there is only ever one instance
    if (User.instance) {
      return User.instance;
    }

    this.currentResults = {};

    /*// debugging
    this.currentResults = {
      min: {
        '1': -1,
        '2': 1,
        '3': 2,
        '4': 3,
        '5': 4,
      },
    };*/

    User.instance = this;
    return this;
  }

  getSelectedAnswers = (groupKey) => {
    const selected = [];
    if (this.currentResults[groupKey]) {
      for (const [answerKey, rating] of Object.entries(this.currentResults[groupKey])) {
        if (rating !== 1) {
          selected.push(answerKey);
        }
      }
    }
    return selected;
  }
  getAnswerRating = (groupKey, answerKey) => {
    if (this.currentResults[groupKey]) {
      return this.currentResults[groupKey][answerKey];
    }
    return -1;
  }

  saveAnswer = (groupKey, answerKey, rating) => {
    if (groupKey && answerKey) {
      // if this group hasn't been saved yet, initialize it
      if (!this.currentResults[groupKey]) {
        this.saveGroup(groupKey, {[answerKey]: 1});
      }

      // save rating for this question
      this.currentResults[groupKey][answerKey] = rating;
    }
  }

  /**
   *
   * @param groupKey
   * @param answers Array of answerKeys that are selected
   */
  saveGroup = (groupKey, answers) => {
    const groupResults = this.currentResults[groupKey] || {};
    for(let answerKey in data[groupKey].options) {
      const answerSelected = answers.includes(answerKey);
      const prevValue = groupResults[answerKey];

      if (answerSelected) {
        // option is checked

        if (prevValue === 1 || prevValue === undefined) {
          // was previously not checked, so force it to be rated
          groupResults[answerKey] = -1
        }
        // else, it was previously rated and is still checked,
        // so leave it alone
      } else {
        // option is not checked. Should have rating of 1
        groupResults[answerKey] = 1;
      }
    }
    this.currentResults[groupKey] = groupResults;
  }

  getNextUrl = (groupKey, currAnswerKey) => {
    const groupResult = this.currentResults[groupKey];

    // Attempt to find next question in current group
    for (const [answerKey, rating] of Object.entries(groupResult)) {
      // skip until after the current answerKey
      if (currAnswerKey && answerKey <= currAnswerKey) {
        continue;
      }

      if (rating !== 1) {
        return `/check/${groupKey}/${answerKey}`;
      }
    }

    // Group complete. Find next group
    const allGroups = Object.keys(data);
    const currGroupIdx = allGroups.indexOf(groupKey);
    const nextGroupKey = currGroupIdx + 1 < allGroups.length ? allGroups[currGroupIdx + 1] : null;

    if (nextGroupKey) {
      return `/check/${nextGroupKey}`;
    }

    const qs = User.buildQuery(this.currentResults);
    this.reset();
    return `/result?${qs}`;
  }

  /**
   * Format querystring for given result
   * Example: ?min=1:-1,2:1,3:4,4:-1&spir=1:5,2:1,3:-1,4:-1
   * @param result
   */
  static buildQuery = (result) => {
    // All groups complete. Go to results
    // format query string: ?min=1:-1,2:1,3:4,4:-1&spir=1:5,2:1,3:-1,4:-1
    const results = Object.keys(result).reduce((acc, key) => {
      acc[key] = JSON.stringify(result[key])
        .replace(/"(\d)":/gm, `$1:`) //unquote question keys
        .replace(/[{}]/gm, ''); //remove start/end brackets
      return acc;
    }, {});

    return decodeURIComponent(new URLSearchParams(results).toString()); // decoding so colons are not encoded
  }
  /**
   * Reset progress value and persist
   * @returns {Promise<void>}
   */
  reset = async () => {
    this.currentResults = {};
  };

}

export default User;