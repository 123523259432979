import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {AuthUserContext} from "../userContext";
import {useFirebase} from "./firebase";

export default function LoginPrompt() {
  const authContext = useContext(AuthUserContext);
  const firebase = useFirebase();
  const authUser = authContext.authUser;

  const signOut = () => {
    firebase.logout();
  }
  if (authContext.isLoggedIn && authUser && !authUser.isAnonymous) {
    return <div className="login-prompt">
      <div className="welcome">
        Welcome back <br />
        <span className="username">{authUser.displayName}</span>!
      </div>
      <div className="not-you">
        Not you?
        <button type="button" className="logout-link" onClick={signOut}>
          Logout
        </button>
      </div>
    </div>
  }
  return <div className="login-prompt">
    Returning user?<br />
    <Link to='/login'>Login</Link> to track results over time.
  </div>
}