import React from 'react';
import {useHistory} from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import data from '../data';

function TestNav({groupKey}) {
  const history = useHistory();
  const group = data[groupKey];
  const groupKeys = Object.keys(data);
  const groupIndex = groupKeys.indexOf(groupKey);
  return (
    <nav className="test-nav">
      <button className="back nav-left" onClick={() => history.goBack()}>
        <ArrowBackIcon />
      </button>
      <div className="nav-title">
        {group.name}
      </div>
      <div className="nav-right">
        {groupIndex + 1} / {groupKeys.length}
      </div>
      <div className="progress">
        <div className="progress-bar" style={{width: Math.round(groupIndex/groupKeys.length*100) + '%'}} />
      </div>
    </nav>
  );
}

export default TestNav;
