import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import Meter from '../components/meter';
import data from '../data';
import {useFirebase} from "../components/firebase";
import {AuthUserContext} from "../userContext";

function Result() {
  const [results, setResults] = useState();
  const firebase = useFirebase();
  const authContext = useContext(AuthUserContext);
  const groups = Object.keys(data).map((key) => ({...data[key], key}));
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const getResults = async (uid) => {
      setResults(await firebase.getUserResults(uid));
    }
    if (authContext.authUser) {
      getResults(authContext.authUser.uid)
    }
  }, [authContext.authUser]);

  const calculateGroupScore = (group) => {
    let score = 0;
    let total = 0;

    for (const answerKey in group) {
      total += 5;
      score += group[answerKey];
    }
    return {score, total};
  }

  let previousResult;
  let previousDateFormatted;
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
  if (results) {
    //todo: pass in from history page the date of this result and filter for correct previous result
    const resultDates = Object.keys(results).sort();
    if (resultDates.length > 1) {
      const previousDateKey = resultDates[resultDates.length-2]; //get second to last. Last is probably current result
      previousDateFormatted = formatter.format(new Date(previousDateKey));
      previousResult = results[previousDateKey];
    }
  }

  return (
    <div className="page page-result">
      <h3>Your Results</h3>

      <div className="results-container">
        {groups.map((group) => {
          let percent = '0';
          let score = 0;
          const qs = query.get(group.key);
          if (qs) {
            score = sumFromQueryValue(qs);
            percent = Math.round((score) / (group.max) * 100) + '%';
          }
          const {score: previousScore, } = previousResult ? calculateGroupScore(previousResult[group.key]) : 0;
          const previousPercent = previousScore ? Math.round( (previousScore) / (group.max) * 100) + '%' : '';
          return (<>
            <div className="group-name" key={`${group.key}-name`}>{group.name}</div>
            <div className="group-value" key={`${group.key}-meter`}>
              <Meter percent={percent} caption={previousScore ? 'current' : null} />
              {previousScore
              ? <Meter percent={previousPercent} caption={previousDateFormatted} className='muted'/>
              : null}
            </div>
            <div className="group-total" key={`${group.key}-total`}>
              {score} / {group.max}
            </div>
          </>);
        })}
      </div>

      <Link to="/" className="btn">Back Home</Link>

      <Link to="/history" className="btn btn-outline btn-accent">View Past Results</Link>
    </div>
  );
}

function sumFromQueryValue(value) {
  const jsonPart = value.replace(/(\d):/gm, `"$1":`);
  const answers = JSON.parse(`{${jsonPart}}`);
  return Object.values(answers).reduce((a, b) => a + b);
}

export default Result;