import React, {useContext, useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import NavigateNext from '@material-ui/icons/NavigateNext';
import {Link, useLocation} from "react-router-dom";
import {useFirebase} from "../components/firebase";
import User, {AuthUserContext} from "../userContext";
import Groups from '../data';
import Meter from "../components/meter";
import {VictoryStack, VictoryArea, VictoryLegend} from 'victory'

function History() {
  const [results, setResults] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const firebase = useFirebase();
  const authContext = useContext(AuthUserContext);

  useEffect(() => {
    const getResults = async (uid) => {
      setResults(await firebase.getUserResults(uid));
      setIsLoading(false);
    }
    if (authContext.authUser) {
      setIsLoading(true);
      getResults(authContext.authUser.uid)
    }
  }, [authContext.authUser]);

  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
  const getScore = (result) => {
    let score = 0;
    let total = 0;

    for(const group in result) {
      for (const answerKey in result[group]) {
        total += 5;
        score += result[group][answerKey];
      }
    }
    return {score, total};
  }

  const resultDisplay = results
    ? <div className="history-table">{Object.keys(results).map((dateKey) => {
      const date = new Date(dateKey);

      const {score, total} = getScore(results[dateKey]);
      const percent = (score/total*100) + '%';
      const qs = User.buildQuery(results[dateKey]);
      const resultLink = `/result?${qs}`;
      return <Link to={resultLink} className="result-row" key={dateKey}>
        <div className="date-cell">{formatter.format(date)}</div>
        <div className="meter-cell"><Meter percent={percent} className='large' caption={`${score}/${total}`} /></div>
        <div className="total-cell">{score}/{total}</div>
        <div className="link-indicator"><NavigateNext /></div>
      </Link>
    })}</div>
    : <div>
        No past results found.<br/>
      <Link to="/check">Start your first check-up now</Link>
    </div>;

  return (
    <div className="page page-history">
      <h3>Your Past Results</h3>

      <div className="results-container">
        {isLoading
          ? <CircularProgress color="inherit" />
          : resultDisplay
        }
      </div>

      {/*<HistoryChart results={results}/>*/}
      <Link to="/" className="btn btn-home">Home</Link>
    </div>
  );
}

function HistoryChart({results}) {
  const resultSums = {};
  const chartData = Object.keys(Groups).reduce((data, groupKey) => {
    data[groupKey] = [];
    return data;
  }, {});

  for (const dateKey in results) {
    const data = {};
    for (const groupKey in results[dateKey]) {
      const groupScore = Object.values(results[dateKey][groupKey]).reduce((t, x) => (t+x), 0);
      data[groupKey] = groupScore;
      chartData[groupKey].push({x: dateKey, y: groupScore});
    }
    resultSums[dateKey] = data;
  }
  return <VictoryStack labels={Object.values(Groups).map((g) => g.name)}>
    <VictoryLegend x={125} y={50}
                   title="Legend"
                   centerTitle
                   />
    {Object.keys(Groups).map((groupKey) => {

      return <VictoryArea data={chartData[groupKey]} />
    })}
  </VictoryStack>
  return <pre><code>{JSON.stringify(chartData, null, 2)}</code></pre>
}
export default History;