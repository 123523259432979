import React, {useContext, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import data from '../data';
import {AuthUserContext, UserContext} from '../userContext';
import TestNav from '../components/testNav';
import {useFirebase} from '../components/firebase';

function TestQuestion() {
  return (
    <UserContext.Consumer>
      {user => (
        <Content user={user} />
      )}
    </UserContext.Consumer>
  );
}
function Content({ user }) {
  const firebase = useFirebase();
  const authContext = useContext(AuthUserContext);
  const authUser = authContext.authUser
  const history = useHistory();
  const params = useParams();
  const groupKey = params.grp || 'min';
  const answerKey = params.q;
  const group = data[groupKey];

  let [rating, setRating] = useState(user.getAnswerRating(groupKey, answerKey));

  function selectRating(rating) {
    setRating(rating);
    user.saveAnswer(groupKey, answerKey, rating);

    goNext();
  }
  function goNext() {
    const results = user.currentResults;
    const nextUrl = user.getNextUrl(groupKey, answerKey);

    // If going to results page, save them since test is complete
    if (nextUrl.indexOf('/result') === 0 && authContext.isLoggedIn) {
      firebase.saveUserResult(authUser.uid, results);
    }

    if (nextUrl) {
      history.push(nextUrl);
    }
  }

  return (
    <UserContext.Consumer>
      {user => (
        <div className="page page-test page-test-group">
          <TestNav groupKey={groupKey} />

          <div className="test-container">
            <div className="up">

              <h3>{group.options[answerKey]}</h3>

              <div className="answer-instructions">
                <span className="action">How often</span> has this been a stress?
              </div>

              <div className="option-list">

                <label className="option-item">
                  <input type="checkbox"
                         name="rating"
                         checked={rating === 1}
                         onChange={() => selectRating(1)}/>
                  <CheckIcon/>
                  <span className="option-name">
                    1 - Hardly ever
                  </span>
                </label>

                <label className="option-item">
                  <input type="checkbox"
                         name="rating"
                         checked={rating === 2}
                         onChange={() => selectRating(2)}/>
                  <CheckIcon/>
                  <span className="option-name">
                    2 - Seldom
                  </span>
                </label>

                <label className="option-item">
                  <input type="checkbox"
                         name="rating"
                         checked={rating === 3}
                         onChange={() => selectRating(3)}/>
                  <CheckIcon/>
                  <span className="option-name">
                    3 - Sometimes
                  </span>
                </label>

                <label className="option-item">
                  <input type="checkbox"
                         name="rating"
                         checked={rating === 4}
                         onChange={() => selectRating(4)}/>
                  <CheckIcon/>
                  <span className="option-name">
                    4 - Often
                  </span>
                </label>

                <label className="option-item">
                  <input type="checkbox"
                         name="rating"
                         checked={rating === 5}
                         onChange={() => selectRating(5)}/>
                  <CheckIcon/>
                  <span className="option-name">
                    5 - Frequently
                  </span>
                </label>
              </div>
              <div className="text-center" style={{display: rating > 0 ? 'block' : 'none'}}>
                <button type="button"
                        className="btn"
                        disabled={!rating || rating === -1}
                        onClick={() => goNext()}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
}

export default TestQuestion;