import React from 'react';
import {Link} from "react-router-dom";

function About() {
  return (
    <div className="page page-about">
      <p>Put a frog in a pot of boiling water, and it will jump right out.</p>

      <p>Put a frog in a pot of tap water and slowly heat it up, and, as the story goes, it will be boiled alive.</p>

      <p>This is an accurate description of the stress undergone by cross-cultural workers. As such, there is a stress inventory available in the Mobile Member Care Team resource site by Barnabas International that is invaluable for gauging their stress levels in various areas of life.</p>

      <p>This is a tool to make it easily available without printouts so that workers don’t have to “stress” about printing it to complete it. ;-)</p>

      <p>This tool is not officially endorsed by Barnabas International.</p>

      <p>As stated in the PDF assessment on the MMCT site:</p>

      <p>This inventory is only meant to be used as a tool to stimulate reflection and discussion
      about sources of cross cultural stress and means of coping. It has not been subjected to
      validation studies and the scores should not be used as a basis for clinical decision making. For
      information about an inventory which has been developed after extensive research see
      www.cernysmith.com.</p>

      <Link to="/" className="btn">Back</Link>
    </div>
  );
}

export default About;