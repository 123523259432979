import React from 'react';
import {Link} from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {useFirebase} from '../components/firebase';

function Login() {
  const firebase = useFirebase();

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.app.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.app.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
  };
  return (
    <div className="page page-login">
      <h1>Login</h1>

      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />

      <Link to="/" className="btn">Back</Link>
    </div>
  );
}

export default Login;