const groups = {
  'min': {
    name: 'Ministry',
    min: 6,
    max: 30,
    options: {
      1: 'Expectations from others',
      2: 'Expectations from myself',
      3: 'Ability to set priorities',
      4: 'Sense of making a difference',
      5: 'Ability to meet my goals',
      6: 'Financial support',
    },
  },
  'spir': {
    name: 'Spiritual',
    min: 5,
    max: 25,
    options: {
      1: 'Relationship with God',
      2: 'Prayer time',
      3: 'Time in the Word',
      4: 'Accountability',
      5: 'Fellowship',
    },
  },
  'rel': {
    name: 'Relational',
    min: 6,
    max: 30,
    options: {
      1: 'Marriage/Housemate relationships',
      2: 'Relationships with family',
      3: 'Relationships with friends',
      4: 'Relationships with leadership',
      5: 'Relationships with co-workers',
      6: 'Relationships with host culture',
    },
  },
  'emo': {
    name: 'Emotional',
    min: 6,
    max: 30,
    options: {
      1: 'Disappointment and frustrations',
      2: 'Hurts and betrayal',
      3: 'Angry feelings',
      4: 'Fears and anxieties',
      5: 'Feeling lack of joy and happiness',
      6: 'Loss of sense of humor',
    },
  },
  'env': {
    name: 'Environmental',
    min: 6,
    max: 30,
    options: {
      1: 'Climate',
      2: 'Traffic',
      3: 'Infrastructure (power/water/email)',
      4: 'Dangers/Instability',
      5: 'Oppression: socio-political/spiritual',
      6: 'Corruption',
    },
  },
  'trma': {
    name: 'Trauma',
    min: 6,
    max: 30,
    options: {
      1: 'Human induced violent trauma',
      2: 'Threat of harm',
      3: 'Accidents',
      4: 'Deaths',
      5: 'Natural Disasters',
      6: 'Loss',
    },
  },
  'clt': {
    name: 'Cross-Cultural',
    min: 6,
    max: 30,
    options: {
      1: 'Cultural values differences',
      2: 'Language',
      3: 'Feeling lonely',
      4: 'Cross cultural expectations',
      5: 'Feel judgmental/critical of culture',
      6: 'Gender differences',
    },
  },
  'hlth': {
    name: 'Health',
    min: 6,
    max: 30,
    options: {
      1: 'Physical well-being',
      2: 'Emotional well-being',
      3: 'Mental well-being',
      4: 'Sexual well-being',
      5: 'Sleep',
      6: 'Time for rest',
    },
  },
  'dev': {
    name: 'Developmental / Transition',
    min: 6,
    max: 30,
    options: {
      1: 'Children: education, adjustment',
      2: 'Aging process (self or parents)',
      3: 'Change in role',
      4: 'Change in location',
      5: 'Change of support system',
      6: 'Retirement',
    },
  },
};

export default groups;
