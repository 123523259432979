import {BrowserRouter as Router, Link, Route, Switch, useHistory, useLocation,} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import User, {UserContext, AuthUserContext} from './userContext';
import Firebase, {FirebaseContext, useFirebase} from './components/firebase';
import {ReactComponent as Frog} from './images/frog.svg';
import Home from './pages/home';
import About from './pages/about';
import Login from './pages/login';
import TestGroup from './pages/testGroup';
import TestQuestion from './pages/testQuestion';
import Result from "./pages/result";
import History from "./pages/history";
import './App.scss';
import LoginPrompt from "./components/loginPrompt";
import {useState} from "react";

function App() {
  return (

    <FirebaseContext.Provider value={new Firebase()}>
      <Router>
        <Layout/>
      </Router>
    </FirebaseContext.Provider>
  );
}

function Layout() {
  const firebase = useFirebase();
  const [isLoggedIn, setIsLoggedIn] = useState(!!firebase.auth().currentUser);
  const [authUser, setAuthUser] = useState(firebase.auth().currentUser);
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      setAuthUser(user);
      setIsLoggedIn(true);
    } else {
      // User is signed out
      setAuthUser(null);
      setIsLoggedIn(false);
    }
  });

  let location = useLocation();
  const history = useHistory();

  let containerClasses = 'App ';
  let isFrogSwimming = false;

  //Specify the duration of the animation (on enter and on exit)
  const timeout = { enter: 5000, exit: 2000 };
  let transitionType = 'pageSlider';
  switch (location.pathname) {
    case "/":
      containerClasses += 'header-full';
      break;
    case "/about":
    case "/login":
      containerClasses += 'header-sm-out';
      break;
    default:
      containerClasses += 'header-sm-in';
      isFrogSwimming = true;
      break;
  }

  if (location.pathname.startsWith('/check/')) {
    transitionType = 'fade';
  } else if (location.pathname.startsWith('/check') && history.action === 'POP') {
    transitionType = 'fade';
  }
  return (
    <AuthUserContext.Provider value={{isLoggedIn, authUser}}>
      <UserContext.Provider value={new User()}>
        <div className={containerClasses}>
          <header>
            <h1>Frog Spa</h1>
            <h2>Hop in!</h2>
            <h2>The water's fine!</h2>
            <h2>...or is it?</h2>

            <Link to='/check' className="btn">Hop In!</Link>

            <LoginPrompt />

            <div className="dive-platform"></div>
          </header>
          <main>
            <Frog className={`frog ${isFrogSwimming ? 'swimming' : ''}`} />

            <TransitionGroup component="div" className="page-container">
              <CSSTransition
                key={location.key}
                timeout={timeout}
                classNames={transitionType}
                mountOnEnter={false}
                unmountOnExit={true}
              >
              <div className="left">
                  <Switch location={location}>
                    <Route path="/about">
                      <About/>
                    </Route>
                    <Route path="/login">
                      <Login/>
                    </Route>
                    <Route path="/check/:grp/:q">
                      <TestQuestion/>
                    </Route>
                    <Route path="/check/:grp">
                      <TestGroup/>
                    </Route>
                    <Route path="/check">
                      <TestGroup/>
                    </Route>
                    <Route path="/result">
                      <Result/>
                    </Route>
                    <Route path="/history">
                      <History/>
                    </Route>
                    <Route path="/">
                      <Home />
                    </Route>
                  </Switch>
                </div>
              </CSSTransition>
            </TransitionGroup>

          </main>
        </div>
      </UserContext.Provider>
      </AuthUserContext.Provider>
  );
}
export default App;
