import app from "firebase/compat/app";
import 'firebase/compat/auth';
import "firebase/database";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const prefix = process.env.REACT_APP_FIREBASE_DATABASE_PREFIX;

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(firebaseConfig);
    }
    this.app = app;
  }

  auth() {
    return app.auth();
  }

  logout() {
    return app.auth().signOut();
  }
  database() {
    return app.database();
  }

  async saveUserResult(userId, result) {
    const db = this.app.firestore();

    const docRef = db.collection(prefix + "user_results").doc(userId);

    var date = new Date(Date.now());
    const key = date.toISOString();
    await docRef.set({
      [key]: result,
    }, { merge: true });
  }

  async getUserResults(userId) {
    const db = this.app.firestore();

    const docRef = db.collection(prefix + "user_results").doc(userId);
    let docSnap = await docRef.get();
    if (docSnap.exists) {
      return docSnap.data();
    }
    return null;
  }
}

export default Firebase;